import http from "@/http";

const Reports = {
    findAll: async function () {
        const resp = await http("/reports");
        return resp.data._embedded.reports;
    },
    save: function (data) {
        return http.post('/reports', data);
    },
    download: async function(id, params, filename) {
        const resp = await http.post(`/reports/${id}/pdf?filename=${filename}`, params, {responseType: 'blob'});
        const blob = new Blob([resp.data], {type: 'application/octet-stream'})
        const link = document.createElement('a')
        link.download = filename;
        link.href = URL.createObjectURL(blob)
        link.click()
        URL.revokeObjectURL(link.href)
    },
    f70Report: async function(params) {
      const resp = await http.get('/requisitions/search/getF70Report', {params});
      return resp.data._embedded.f70ReportDetails;
    },
};

export default Reports;
