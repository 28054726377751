<template>
  <div>
    <b-form class="mb-2" inline>

      <div class="w-25 pr-1">
        <b-datepicker locale="es-MX" :placeholder="$t('FROM_LABEL')" v-model="filter.from"></b-datepicker>
      </div>

      <div class="w-25 pr-1">
        <b-datepicker locale="es-MX" :placeholder="$t('TO_LABEL')" v-model="filter.to"></b-datepicker>
      </div>

      <div class="w-25 pr-1">
        <company-single-selector v-model="filter.supplierId" value-type="id" undefined-means="ALL_SUPPLIERS_LABEL" :type="'SUPPLIER'" :allow-add="false"></company-single-selector>
      </div>

      <div class="w-25">
        <expenditure-type-single-selector v-model="filter.expenditureTypeId" value-type="id" undefined-means="ALL_EXPENDITURE_TYPES_LABEL" :allow-add="false"></expenditure-type-single-selector>
      </div>

      <div class="w-100 mt-1">
        <project-single-selector value-type="id" undefined-means="PROJECT_FILTER_ALL_LABEL" v-model="filter.projectId"></project-single-selector>
      </div>

      <div class="w-25 mt-1">
        <b-button :disabled="$v.filter.$invalid" variant="primary" @click="search">
          <b-icon-search></b-icon-search>
          {{$t('REPORT_GENERATE_LABEL') | capitalize}}
        </b-button>
      </div>

    </b-form>
  </div>
</template>

<script>
import {VModel, CapitalFilter, Filter} from "@/mixins";
import eventBus from "@/events";
import CompanySingleSelector from "@/company/CompanySingleSelector";
import ExpenditureTypeSingleSelector from "@/expenditureType/ExpenditureTypeSingleSelector";
import {required} from "vuelidate/lib/validators";
import ProjectSingleSelector from "@/project/ProjectSingleSelector";

export default {
  name: "ProjectListFilter",
  components: {ProjectSingleSelector, ExpenditureTypeSingleSelector, CompanySingleSelector},
  props: ['onlyActive'],
  mixins: [Filter, VModel, CapitalFilter],
  mounted() {
    eventBus.$on('CLEAR_FILTER', () => {
      this.filter = {}
    })
  },
  data() {
    return {
      filter: {}
    }
  },
  methods: {
    search() {
      if (this.onlyActive) {
        this.filter.status = true;
      }
      this.$emit('search', this.filter);
    }
  },
  validations: {
    filter: {
      from: {required},
      to: {required},
    }
  }
}
</script>

<style scoped>

</style>
