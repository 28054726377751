<template>
  <div>
    <div class="pb-3">
      <!-- Excel export -->
      <download-excel class="ml-2 btn btn-dark"
                      v-if="reportDetails && reportDetails.length > 0"
                      :data="reportDetails"
                      :fields="excelFields"
                      :worksheet="labels.module"
                      :name="labels.excel">
        <b-icon-download class="mr-1"></b-icon-download>
        {{ $t('LIST_EXCEL_DOWNLOAD_LABEL') | capitalize}}
      </download-excel>
      <!-- Excel export -->

      <b-button class="ml-1 btn btn-dark" v-b-toggle.filter-collapse>
        <b-icon-search class="mr-1"></b-icon-search>
        {{$t('FILTER_LABEL') | capitalize}}
      </b-button>
    </div>
    <b-collapse id="filter-collapse" class="mt-2">
      <!-- List page filter -->
      <f70-report-filter :only-active="onlyActive" class="d-inline-block w-100" @search="setFilter" v-model="filter"></f70-report-filter>
      <!-- List page filter -->
    </b-collapse>

    <!-- table -->
    <b-table
        id="f70-report-table"
        ref="f70ReportTable"
        striped
        hover
        bordered
        small
        table-variant="dark"
        selectable
        :select-mode="selectMode"
        v-model="items"
        :current-page="page"
        :sort-by="sort"
        :per-page="size"
        :busy.sync="isBusy"
        :fields="fields"
        :filter="filter"
        :items="reportDetails">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('INVOICES_LABEL')})  | capitalize}}</strong>
        </div>
      </template>

      <!-- requisition -->
      <template #head(requisition)="">
        <span>{{ labels.requisition | capitalize}}</span>
      </template>

      <!-- budget -->
      <template #head(budget)="">
        <span>{{ labels.budget | capitalize}}</span>
      </template>

      <!-- paymentDate -->
      <template #head(paymentDate)="">
        <span>{{ labels.paymentDate | capitalize}}</span>
      </template>

      <template #cell(paymentDate)="data">
        {{data.item.paymentDate | date}}
      </template>

      <!-- invoiceDate -->
      <template #head(invoiceDate)="">
        <span>{{ labels.invoiceDate | capitalize}}</span>
      </template>

      <template #cell(invoiceDate)="data">
        {{data.item.invoiceDate | date}}
      </template>

      <!-- invoice -->
      <template #head(invoice)="">
        <span>{{ labels.invoice | capitalize}}</span>
      </template>

      <!-- supplier -->
      <template #head(supplier)="">
        <span>{{ labels.supplier | capitalize}}</span>
      </template>

      <!-- total -->
      <template #head(total)="">
        <span>{{ labels.total | capitalize}}</span>
      </template>

      <template #cell(total)="data">
        {{data.item.total | currency}}
      </template>

      <!-- description -->
      <template #head(description)="">
        <span>{{ labels.description | capitalize}}</span>
      </template>

      <!-- detail -->
      <template #head(detail)="">
        <span>{{ labels.detail | capitalize}}</span>
      </template>

      <!-- expenditure -->
      <template #head(expenditure)="">
        <span>{{ labels.expenditure | capitalize}}</span>
      </template>

      <!-- donor -->
      <template #head(donor)="">
        <span>{{ labels.donor | capitalize}}</span>
      </template>

    </b-table>
    <!-- table -->
  </div>
</template>

<script>
import F70ReportFilter from "@/reports/F70ReportFilter";
import {DateFilter, Form, NumberFilter, ProcessWithLoadingAndMessage, Table} from "@/mixins";
import Reports from "@/reports";

export default {
  name: "F70ReportComponent",
  props: ['selectMode', 'currentlySelected', 'onlyActive'],
  components: {F70ReportFilter},
  mixins: [Table, Form, ProcessWithLoadingAndMessage, DateFilter, NumberFilter],
  methods: {
    setFilter: function (filter) {
      this.filter = filter;
      this.report();
    },
    async report() {
      this.isBusy = true;
      this.reportDetails = await Reports.f70Report(this.filter);
      this.isBusy = false;
    }
  },
  data() {
    return {
      reportDetails: [],
      labels: {
        requisition: this.$t('F70_REQUISITION_LABEL'),
        budget: this.$t('F70_BUDGET_LABEL'),
        paymentDate: this.$t('F70_PAYMENT_DATE_LABEL'),
        invoiceDate: this.$t('F70_INVOICE_DATE_LABEL'),
        invoice: this.$t('F70_INVOICE_LABEL'),
        supplier: this.$t('F70_SUPPLIER_LABEL'),
        total: this.$t('F70_TOTAL_LABEL'),
        description: this.$t('F70_DESCRIPTION_LABEL'),
        detail: this.$t('F70_DETAIL_LABEL'),
        expenditure: this.$t('F70_EXPENDITURE_LABEL'),
        donor: this.$t('F70_DONOR_LABEL'),

        enabled: this.$t('LIST_ENABLED_LABEL'),
        module: this.$t('PROJECTS_LABEL'),
        excel: this.$t('PROJECTS_LABEL') + '.xls',
      },
      registerPage: 'PROJECT_REGISTER_TITLE',
      editPage: 'PROJECT_EDIT_TITLE',
      selectedCount: 0,
      allSelected: false,
      originals: []
    }
  },
  computed: {
    controller() {
      return Reports;
    },
    fields() {
      return [
        {key: 'requisition', sortable: false, tdClass: 'customTd'},
        {key: 'budget', sortable: false, tdClass: 'customTd'},
        {key: 'paymentDate', sortable: false, tdClass: 'customTd'},
        {key: 'invoiceDate', sortable: false, tdClass: 'customTd'},
        {key: 'invoice', sortable: false, tdClass: 'customTd'},
        {key: 'supplier', sortable: false, tdClass: 'customTd'},
        {key: 'total', sortable: false, tdClass: 'customTd'},
        {key: 'description', sortable: false, tdClass: 'customTd'},
        {key: 'detail', sortable: false, tdClass: 'customTd'},
        {key: 'expenditure', sortable: false, tdClass: 'customTd'},
        {key: 'donor', sortable: false, tdClass: 'customTd'},
      ];
    },
    tableRef() {
      return this.$refs.f70ReportTable;
    },
    excelFields() {
      const fields = {};
      fields[this.labels.requisition] = 'requisition';
      fields[this.labels.budget] = 'budget';
      fields[this.labels.paymentDate] = 'paymentDate';
      fields[this.labels.invoiceDate] = 'invoiceDate';
      fields[this.labels.invoice] = 'invoice';
      fields[this.labels.supplier] = 'supplier';
      fields[this.labels.total] = 'total';
      fields[this.labels.description] = 'description';
      fields[this.labels.detail] = 'detail';
      fields[this.labels.expenditure] = 'expenditure';
      fields[this.labels.donor] = 'donor';
      return fields;
    }
  }
}
</script>

<style scoped>

</style>