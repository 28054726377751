<template>
  <div>
    <div v-if="loading" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('PROJECTS_LABEL')})  | capitalize}}</strong>
    </div>
    <b-select
        v-else
        :disabled="disabled"
        id="project-input" type="text"
        :state="state"
        :value="content"
        @change="handleInput" class="w-100">
      <b-select-option :value="undefined">{{$t(undefinedMeans) | capitalize}}</b-select-option>
      <b-select-option :key="item.id" v-for="item in items" :value="valueType === 'id' ? item.id : item._links.self.href">{{item.title}}</b-select-option>
    </b-select>
    <b-form-invalid-feedback id="project-input-feedback">
      <div v-for="error in errors" :key="error.error">
        {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('PROJECT_LABEL')},})  | capitalize }}
      </div>
    </b-form-invalid-feedback>
  </div>

</template>

<script>
import {VModel, SendsMessages} from "@/mixins";
import Project from "@/project/index";

export default {
  name: "ProjectSingleSelector",
  props: ['state', 'errors', 'disabled', 'valueType', 'undefinedMeans'],
  mixins: [VModel, SendsMessages],
  async mounted() {
    try {
      this.loading = true;
      this.items = await Project.findAll({size: 1000, filter: {status: true}});
    } catch(e) {
      this.sendError('ERROR_LOADING_CATALOG', {}, e);
    } finally {
      this.loading = false;
    }
  },
  data() {
    return {
      loading: false,
      items: []
    }
  },
  methods: {
    handleInput(val) {
      this.$emit('input', val);
      const test = this.items.find(item => item._links.self.href === val);
      this.$emit('projectSelected', test);
    },
  }
}
</script>

<style scoped>

</style>
